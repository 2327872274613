const CLIENT_ID = process.env.CLIENT_ID || '{clientId}';
const ISSUER = process.env.ISSUER || 'https://{yourOktaDomain}.com/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const ENV = process.env.ENV || false; 
const REDIRECTURI = process.env.REDIRECTURI
var APIHOST = 'https://api.theiobot.com'
if ( ENV === 'DEV' ) APIHOST = 'http://localhost:3000'
if ( ENV === 'STAG') APIHOST = 'https://staging.theiobot.com'

console.log('config env = ', ENV)
console.log('config APIHOST = ', APIHOST)
console.log('config REDIRECTURI = ', REDIRECTURI)
 
export default { 
  oidc: { 
    clientId: CLIENT_ID,
    issuer: ISSUER, 
    cookies: {
      secure: false
    },
    redirectUri: REDIRECTURI,
    env:ENV,
    apiHost:APIHOST,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    idps:[
      {type: 'GOOGLE', id: '0oa41luvfm2dbN6i9357'}
    ],
    idpDisplay :"PRIMARY", 

  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
}; 

