/* 
	TODO:
		when you are "all" you should be able to see all factories?
		FactoryQuote should be calculated
		FOB should be u/d
		error: unable to retrieve oauth redirect param cookie
*/


import React, { Component, useEffect } from 'react';
 
import * as formik from 'formik';
import * as yup from 'yup';

import {Form, Col, Button, Spinner, InputGroup, Alert} from 'react-bootstrap';
import { withOktaAuth } from '@okta/okta-react';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import bsCustomFileInput from 'bs-custom-file-input';
import SuccessModal from './SuccessModal'
import axios from 'axios';
import { useHistory } from "react-router-dom";
import config from './config';

import * as Sentry from '@sentry/react';

const { env, apiHost } = config.oidc;

const { Formik } = formik;

var numberError = yup.number().typeError('Field must be a number').min(0,'Field must be greater than or equal to zero')
// console.log(env)
// env !== 'DEV' // put this in below to speed dev time.
if (true) {
	console.log('required fields')
	numberError = numberError.required() 
} else {
	console.log('not all fields required')
}
 
const schema = yup.object({
	Season: yup.string().required(),
	Factory: yup.string().required(),
	inputfile: yup.string().required(),
	SampleStage: yup.string().required(),
	ProtoNumber: yup.string().required(),
	StockNumber: yup.string().when("SampleStage", {
		is:  function (val){return (val !=='First Proto')} , 
		then: yup.string().required(
			"Stock Number is required when Sample Stage is not Proto"
		),
		otherwise: yup.string()
	}),
	FactoryQuote: numberError,
	LeatherUpper: numberError,
	LeatherLining: numberError,
	TextileTotal: numberError,
	Components: numberError,
	Outsole: numberError,
	Footbed: numberError,
	Trims: numberError,
	Finish: numberError,
	Packaging: numberError,
	ExtraLabor: numberError,
	Amortization: numberError,
	LOP: numberError,
	Discount: numberError,
	CuttingDies: numberError,
	Lasts: numberError,
});



export default withOktaAuth(class FormExample extends Component {

	constructor(props) {
		super(props);
		this.state = { 
			userInfo: null, 
			factory: null, 
			factoryList: null,
			seasonList: null,
			sampleStagesList: getSampleStagesList(),
			loadingMessage:'Loading...', 
			loading:false,
			cbdList: [],
			fileLable: '---',
			showCBDResults : false,
			enableSubmit: false,
			CBDFob : false,
			resetForm: false,
			showCalcFields: false,
		 };
		this.checkUser = checkUser.bind(this)
		this.getToken = getToken.bind(this)
		this.getAuthorizedFactoryFromAirtable = getAuthorizedFactoryFromAirtable.bind(this)
		this.getConstantsFromAirtable = getConstantsFromAirtable.bind(this)
		this.loadingTimeoutLimit = 5000
		this.successTitle = "Submission Complete"
		this.successMessage =  "You may continue to make updates"
	}

	

	logout = async () => {
		try {
		  if (!this.props.authState.isAuthenticated) useHistory().push('/')
		  else this.props.authService.logout('/');
		} catch (error) {
		  console.error(error)
		  useHistory().push('/')
		}
	  }

	async getUserToken(){

		var token = this.getToken()

		// console.log('token - ', token)

		if (!token) {
			this.onSuccessModal("Session Timeout", "You will need to log in again to continue")
			setTimeout(()=>{this.logout()},5000)
		}

		return token
	}

	async componentDidMount() {
		this.checkUser();
		this.loadingTimeout = setTimeout(() => 
			{this.setState({loadingMessage: 'There may be an issue with your connection - please refresh the page. Or log out and log back in/ '})
			}, this.loadingTimeoutLimit)
	}
	
	async componentDidUpdate() {
		this.checkUser();
	}

	componentWillUnmount() {
		clearTimeout(this.loadingTimeout)
	  }
	
	successModalRef = ({handleShow}) => {
		this.showModal = handleShow;
	}

	onSuccessModal = (title, message) => {
		this.showModal(title, message);
	}

	startSpinner = () => {
		this.setState({ loading: true })
	}
	stopSpinner = () => {
		this.setState({loading:false})
	}

	addToCBDList = (item) => {
		this.state.cbdList.push(item)
		this.setState({cbdList:this.state.cbdList})
	}

	handleSubmitError = (error, extraMessage ) => {

		var msg = false

		if (error.toString().match('401')){
			msg = "Session Timeout"
			this.onSuccessModal("Session Timeout", "You will need to log in again to continue")
			setTimeout(()=>{this.logout()},5000)
		} else if (error.toString().match('CBD')){
			msg = "CBD Parse Error " + extraMessage
			this.onSuccessModal("CBD Error", `You have chosen a CBD that is either in an incorrect format or contains incorrect data. \n ${error.toString()} ${extraMessage || ''}`)
		} else {
			msg = "Submission Error" 
			this.onSuccessModal("Submission Error", `The form could not be submitted at this time. 
			Please try again and if the issue persists, please contact Cole Haan Costing. 
			${error.toString()} : ${(error.response && error.response.data && error.response.data.errorMsg) ? error.response.data.errorMsg : ''}`)
		}

		let extra = (msg) ? {extra:[{msg:msg}]} : {}
		Sentry.captureException(error,extra)

	}

	checkCBD = async ( setFieldValue, inputfile) => {

		this.startSpinner()
		this.setState({showCBDResults: false})
		this.setState({enableSubmit: false})

		const formData = new FormData();
		var errorMsgAppend = ''

		try {
			
			formData.append('inputfile', inputfile)

			const accessToken = await this.getUserToken();

			if (accessToken){
				// console.log(accessToken)
				let ret = await axios.post(`${apiHost}/costing/put/parseCBD`, formData, { headers: { 
						'Content-Type': 'multipart/form-data',
						'Authorization': `'Bearer ${accessToken}'`
					}})

				// console.log(ret)

				const summaryData 	= ret.data.summaryData
				const costData 		= ret.data.costData
				var match = false
				var data = null
				var dataFieldName = null
				var appFieldName

				errorMsgAppend = 'Please correct this data in the CBD and try again.'

				// TODO - replace dataFieldName / appFieldName with a mapping
				
				// set season
				dataFieldName = 'Season'
				appFieldName = dataFieldName
				data = summaryData[dataFieldName]
				match = getSelectFieldMatchFromCBD(data, this.state.seasonList)
				if (match === false) throw new Error(`Error in ${dataFieldName} field. ${errorMsgAppend}`)
				else setFieldValue(appFieldName, match)
				
				// set sample stage
				dataFieldName = 'Sample Stage'
				appFieldName = 'SampleStage'
				data = costData[dataFieldName]
				match = getSelectFieldMatchFromCBD(data, this.state.sampleStagesList)
				if (match === false) throw new Error(`Error Parsing ${dataFieldName}. ${errorMsgAppend}`)
				else setFieldValue(appFieldName, match)

				// set Gender
				// made difficult in that it is a free text field
				dataFieldName = 'Gender'
				appFieldName = 'Gender'
				data = summaryData[dataFieldName]
				match = getGenderMatchFromCBD(data)
				
				if (match === false) throw new Error(`Error Parsing ${dataFieldName}. Entry must be either Men's or Women's ${errorMsgAppend}`)
				else setFieldValue(appFieldName, match)

				// set proto
				dataFieldName = 'Proto'
				appFieldName = 'ProtoNumber'	
				data = costData[dataFieldName] || null
				if (!data) throw new Error(`Error Parsing ${dataFieldName}. ${errorMsgAppend}`)
				else setFieldValue(appFieldName, data)

				// set stockid
				dataFieldName = 'Stock Number'
				appFieldName = 'StockNumber'
				data = scrubStockNumber( summaryData[dataFieldName] ) 
				// console.log('scrubbed = ', data)
				if (data) setFieldValue(appFieldName, data)
				// else console.log('no match for stock number', summaryData[dataFieldName])

				// set Final FOB
				dataFieldName = 'Final FOB'
				appFieldName = 'FactoryQuote'	
				data = costData[dataFieldName] || null
				if (!data) throw new Error(`Error Parsing ${dataFieldName}. ${errorMsgAppend}`)
				else {
					// console.log('appFieldName',appFieldName, data);
					this.setState({showCBDResults: true})
					this.setState({CBDFob: data})
					setFieldValue('showCBDResults',true)
					// setFieldValue('FactoryQuote', data)
				}

				// Check to see if all the numeric fields exist
				// TODO move this out
				const CBDKeys = getCBDMapKeys()
				var keyCheck = {}
				const getKey = (obj,val) => Object.keys(obj).find(key => obj[key] === val);

				Object.keys(CBDKeys).forEach(key => {
					if ( costData[key] !== undefined ) {
						let formKey = null
						if ( CBDKeys[key] === true ) formKey = key
						else if( CBDKeys[key] !== true && CBDKeys[key] !== false ) formKey = CBDKeys[key]

						if (formKey) keyCheck[formKey] = true
					}
				})
				var checkFail = []
				Object.keys( getShoeComponentNumericFields() ).forEach(key => {
					if ( keyCheck[key] !== true ) {
						let theKey = getKey(getCBDMapKeys(), key)
						console.log('key not found in CBD ', theKey )
						checkFail.push(theKey)
					}
				})

				var costDataKeyFail = []
				Object.keys( costData ).forEach(key => {

					if (CBDKeys[key] === undefined) {
						costDataKeyFail.push(key.replace('\n','\\n').replace('\r','\\r'))
						console.log('unmatched costData key = ', key.replace('\n','\\n').replace('\r','\\r'))
					}
					
				})

				if( checkFail.length > 0 ) throw new Error(`The following fields were missing or mis-spelled in the CBD: ${checkFail.toString()}. ${ (costDataKeyFail.length > 0) ? 'You may want to review the following CBD field(s): '+costDataKeyFail.toString() : '' }`)

				// populate the number fields here
				let dataFromCBD = true
				this.updateShoeComponentNumericFields(costData, setFieldValue, dataFromCBD)


				
				this.stopSpinner()
				this.setState({enableSubmit: true}) // TODO what about a match for CBD and calculated FOB

			} else {
				// should have redirected on getUserToken
				console.log('should not get here')
			}

		} catch (error) {
			
			this.setState({showCBDResults: false})
			this.setState({enableSubmit: false})
			this.setState({CBDFob: ''})
			let sheetErrorMsg = 'Sheet Named Incorrectly - Must include Sheet Named "Summary Worksheet"'
			let defaultCBDErrorMsg = 'CBD Error ' + error
			let errorMsg = (
							error.response && 
							error.response.data && 
							error.response.data.errorMsg && 
							error.response.data.errorMsg.match('Sheet')) ? sheetErrorMsg : defaultCBDErrorMsg;
			this.stopSpinner()
			this.handleSubmitError(errorMsg, error.response)
		}

	}

	submitForm = async (formResults, formik) => {
		// console.log('submit form')

		try {


			// check the match TODO - move this elsewhere
			if (this.state.CBDFob !== formResults.FactoryQuote || !formResults.FactoryQuote ) {
				let msg = ''
				if (this.state.CBDFob !== formResults.FactoryQuote) msg = 'CBD Total and Component Totals do not match. Please check your calculations and re-submit'
				else if (!formResults.FactoryQuote) msg = 'FOB could not be derived from CBD.'
				throw new Error(msg)
			}

			this.startSpinner()
			this.setState({showCBDResults: false})
			
			const formData = new FormData();
			const KeysMap = getAirMapKeys()
			Object.keys(KeysMap).map((key,index)=>{
				// console.log(` key ${key}, ${formResults[key]}, ${formResults[KeysMap[key]]} ${KeysMap[key]}`)
				
				if (KeysMap[key]){
					if (KeysMap[key] === true && formResults[key] !== undefined && formResults[key] !== ''){
						formData.append(key, formResults[key])
					} else if(formResults[KeysMap[key]] !== undefined && formResults[KeysMap[key]] !== '') {
						formData.append(KeysMap[key], formResults[KeysMap[key]])
					}
				}
				return true
			})

			formData.append('inputfile', formResults.inputfile)

			// console.log('data = ', formData.getAll('inputfile'))

			const accessToken = await this.getUserToken();

			// console.log('TOKEN **** ')
			// console.log(accessToken)

			// TODO: a lot better error handling here
			if (accessToken){
				let ret = await axios.post(`${apiHost}/costing/put`, formData, { headers: { 
						'Content-Type': 'multipart/form-data',
						'Authorization': `'Bearer ${accessToken}'`
					}})

				console.log(ret)
				
				// TODO add error handle
				this.addToCBDList(JSON.stringify({ "Proto Number": formResults.ProtoNumber, "Stock Number":formResults.StockNumber }))
				

				this.setState({fileLable: this.state.fileLable+'-'})

				// if (env !== 'DEV') {
					formik.resetForm()
				// } 
				
				this.stopSpinner()
				this.setState({enableSubmit: false})

				this.onSuccessModal(this.successTitle, this.successMessage)

			} else {
				// should have redirected on getUserToken
				console.log('should not get here')
			}

		} catch (error) {
			this.stopSpinner()
			this.handleSubmitError(error)
		}

	}

	// TODO: do we still use this?
	// answer: nope
	lookupAirData = async (setFieldValue, data) =>{
							
		this.startSpinner()
	
		try {
			// TODO: a lot better error handling
			const accessToken = await this.getUserToken();
	
			let res = await axios.post(`${apiHost}/costing/get`, 
				data,
				{ headers: { 
					'Authorization': `'Bearer ${accessToken}'`
				}}
			)
			
			const costData = res.data
			this.updateShoeComponentNumericFields(costData, setFieldValue)
	
			this.stopSpinner()
	
		} catch (error) {
			this.stopSpinner()
			this.handleSubmitError(error)
			return false
		}
	}

	updateShoeComponentNumericFields = (costData, setFieldValue, dataFromCBD) => {

		// is the data coming from CBD? choose appropriate map
		// TODO: not using this any longer data is always coming from CBD - we no longer do a lookup
		const AirMapKeys = (dataFromCBD) ? getCBDMapKeys() : getAirMapKeys()
		const factoryNumericFields = getShoeComponentNumericFields()
		const factory = this.state.authorizedFactory
		
		Object.keys(AirMapKeys).map((key, index)=>{
			// TODO: I don't understand why this is here at all looks redundant - factoryNumericFields[AirMapKeys[key]]
			
			let defaultVal = (factoryNumericFields[key] || factoryNumericFields[key] === 0 || factoryNumericFields[AirMapKeys[key]] || factoryNumericFields[AirMapKeys[key]] === 0) ? '0' : ''
			let theData = costData[key] ||  defaultVal

			// console.log( key, costData[key], defaultVal, factoryNumericFields[key] , factoryNumericFields[key] === 0 , factoryNumericFields[AirMapKeys[key]] === 0 )
			// console.log('defaultVal = ', defaultVal)
			// console.log('key = ', key)
			// console.log('costData[key]', costData[key])
			// console.log('defaultVal = ', defaultVal)
			// console.log('factoryNumericFields[key] = ', factoryNumericFields[key])
			// console.log('factoryNumericFields[AirMapKeys[key]] = ', factoryNumericFields[AirMapKeys[key]])
			

			let formKey = null
			if ( AirMapKeys[key] === true ) formKey = key
			else if( AirMapKeys[key] !== true && AirMapKeys[key] !== false ) formKey = AirMapKeys[key]
	
			// console.log("key = ", key)
			// Do not prepopulate the following Fields and (but Factory if you have access to ALL factories)
			if (
					formKey && 
					formKey !== 'StockNumber' && 
					formKey !== 'ProtoNumber' && 
					formKey !== 'SampleStage' && 
					(factory === 'ALL' || formKey !== 'Factory') &&  
					( dataFromCBD || formKey !== 'Season') &&  // TODO - (maybe!) move update logic from CBD parse to here
					( dataFromCBD || formKey !== 'Gender')
				)
			{
				// console.log(formKey, theData)
				if (formKey) setFieldValue(formKey, theData )
				
			}
	
			return true
	
		})
		
	}

	render(){

		if (!this.state.authorizedFactory) { 
			return (
				<div>
					<div>{this.state.loadingMessage}</div>
				</div>
			)
		} else {
	
			return (
			
			<Container className="p-3">

				<div>
					{this.state.userInfo &&
					<div>
						<p>Welcome back, {this.state.userInfo.email} @ {this.state.authorizedFactory}</p>
					</div>
					}
				</div>

				<div>
						<span>
							<div>
								{this.state.cbdList.length>0 && <p>&nbsp;</p>}
								{this.state.cbdList.length>0 && <p>You have submitted the following:</p>}
								{this.state.cbdList.map( (val, index) => {
								// console.log('val',val)
								return(
									
									<p key={index+1}>{index+1}. Proto Number:{ JSON.parse(val)["Proto Number"] }, Stock Number: { JSON.parse(val)["Stock Number"] }</p>)
									
								})}
							</div>
						</span>
						<p>&nbsp;</p>
				</div>

				<SuccessModal ref={this.successModalRef} ></SuccessModal>
				
				<Formik
				validationSchema={schema}
				onSubmit={this.submitForm}
				initialValues={{
					disableFields: true,
					showCBDResults : this.state.showCBDResults,
					AllFactories: this.state.factoryList,
					AllSeasons: this.state.seasonList,
					AllSampleStages: this.state.sampleStagesList,
					Factory: this.state.authorizedFactory,
					inputfile: '',
					Season:'',
					SampleStage: '',
					ProtoNumber: '',
					StockNumber: '',
					Gender:'',
					FactoryQuote:'',
					LeatherUpper:0,
					LeatherLining:0,
					TextileTotal:0,
					Components:0,
					Outsole:0,
					Footbed:0,
					Trims:0,
					Finish:0,
					Packaging:0,
					ExtraLabor:0,
					Amortization:0,
					LOP:0,
					Discount:0,
					CuttingDies:0,
					Lasts:0,
					}}
				>
					
				{({
					handleSubmit,
					handleChange,
					setFieldValue,
					resetForm,
					handleBlur,
					props,
					values,
					touched,
					isValid,
					isSubmitting,
					errors,
					status,

					
				}) => (

					
					<Form noValidate onSubmit={handleSubmit} >
					<MyOnChangeComponent />
					

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationinputfile">
							<Form.Label>CBD Upload</Form.Label>
							
							<Form.File 
								id="inputfile"
								label={this.state.fileLable}
								data-browse="Choose CBD"
								custom
								

								onChange={(event)	 => {
										bsCustomFileInput.init();
										// console.log('change', event.currentTarget.files[0])
										if (event.currentTarget.files[0]) {
											if (values.inputfile !== event.currentTarget.files[0]){
												// console.log('checking cbd');
												resetForm()
												this.checkCBD( setFieldValue, event.currentTarget.files[0])
											}

											setFieldValue("inputfile", event.currentTarget.files[0])

											
										}
									} 
								}

								onClick={(event)=>{ 
									event.currentTarget.value=''
								}}

								isValid={touched.inputfile && !errors.inputfile} 
								isInvalid={touched.inputfile && errors.inputfile}
							/>
							{/* {console.log(errors)} */}
							
							{/* {console.log(values.inputfile)} */}
							{(touched.inputfile && errors.inputfile) ? <div className="invalid-feedback" style={{display:'block'}}>CBD Upload is a required field</div> : '' }
							

						</Form.Group>
					</Form.Row>
					{this.state.showCBDResults && 
					<Form.Row>
						<Form.Group as={Col} md="8" >
						{/* <Form.Label> */}
							<Alert variant={(this.state.showCBDResults &&  this.state.CBDFob === values.FactoryQuote ) ? 'success' : 'danger' }>
								Final FOB From CBD: {this.state.CBDFob}								
							</Alert>
							{this.state.showCBDResults && this.state.CBDFob !== values.FactoryQuote &&
							<Alert variant='danger'>
								Does not Match Calculated FOB: {values.FactoryQuote}								
							</Alert>
							}
						{/* </Form.Label> */}
						
						</Form.Group>
					</Form.Row>
					}

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationSeason">
						<Form.Label>Season</Form.Label>
						<Form.Control
							as="select"
							name="Season"
							value={values.Season}
							onChange={handleChange}
							isValid={values.Season !=='' && !errors.Season }
							isInvalid={touched.Season && errors.Season}
						>
							<option label='Select...' key=''>''</option>
							{(values.AllSeasons) && values.AllSeasons.map((val)=>{
								// using val.name here because we are not using linked records in Airtable.
								// if we move to linked records, we would use val.id as the option value
								// return(<option key={val.id} label={val.name}>{val.id}</option>) 
								return(<option key={val.id} label={val.name}>{val.name}</option>)
							})}

						</Form.Control>
						{/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
						<Form.Control.Feedback type='invalid'>
							{errors.Season}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikSampleStage">
						<Form.Label>Sample Stage</Form.Label>
							<Form.Control
							as="select"
							name="SampleStage"
							value={values.SampleStage}
							onChange={handleChange}
							isValid={touched.SampleStage && !errors.SampleStage}
							isInvalid={touched.SampleStage && errors.SampleStage}
							>
							<option label='Select...'></option>
							{(values.AllSampleStages) && values.AllSampleStages.map((val)=>{
								return(<option key={val.name} label={val.name}>{val.name}</option>)
							})}
							</Form.Control>
							
							<Form.Control.Feedback type='invalid'>
							{errors.SampleStage}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikProtoNumber">
						<Form.Label>Proto Number</Form.Label>
						<InputGroup>
							<Form.Control
								type="text"
								name="ProtoNumber"
								value={values.ProtoNumber}
								// onBlur={(event)=>{ this.lookupAirData(setFieldValue, {ProtoNumber: values.ProtoNumber, StockNumber:values.StockNumber, requestingFactory:this.state.authorizedFactory} );  }}
								onChange={handleChange}
								isValid={touched.ProtoNumber && !errors.ProtoNumber}
								isInvalid={touched.ProtoNumber && errors.ProtoNumber}
							/>
							{/* <InputGroup.Append>
								{this.state.loading ? <Button variant="primary" disabled>
									<Spinner
									as="span"
									animation="grow"
									size="sm"
									role="status"
									aria-hidden="true"
									/>
									Loading...
								</Button> 
								: 
								<Button variant="primary" type="button" onClick={(event)=>{ this.lookupAirData(setFieldValue, {ProtoNumber: values.ProtoNumber, StockNumber:values.StockNumber, requestingFactory:this.state.authorizedFactory} );  }}>Lookup</Button> }
							</InputGroup.Append> */}
							
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.ProtoNumber}
						</Form.Control.Feedback>
						</InputGroup>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikStockNumber">
						<Form.Label>Stock Number</Form.Label>
						<InputGroup>
							<Form.Control
								type="text"
								name="StockNumber"
								value={values.StockNumber}
								// onBlur={(event)=>{ this.lookupAirData(setFieldValue, {ProtoNumber: values.ProtoNumber, StockNumber:values.StockNumber, requestingFactory:this.state.authorizedFactory} );  }}
								onChange={handleChange}
								isValid={touched.StockNumber && !errors.StockNumber}
								isInvalid={touched.StockNumber && errors.StockNumber}
							/>
							{/* <InputGroup.Append>
								{this.state.loading ? <Button variant="primary" disabled>
									<Spinner
									as="span"
									animation="grow"
									size="sm"
									role="status"
									aria-hidden="true"
									/>
									Loading...
								</Button> 
								: 
								<Button variant="primary" type="button" onClick={(event)=>{ this.lookupAirData(setFieldValue,  {ProtoNumber: values.ProtoNumber, StockNumber:values.StockNumber, requestingFactory:this.state.authorizedFactory} );  }}>Lookup</Button> }
							</InputGroup.Append> */}
							<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
							<Form.Control.Feedback type='invalid'>
								{errors.StockNumber}
							</Form.Control.Feedback>
						</InputGroup>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFactory">
						<Form.Label>Factory</Form.Label>
						<Form.Control
							as="select"
							name="Factory"
							value={values.Factory}
							onChange={handleChange}
							isValid={ values.Factory !== 'ALL' && !errors.Factory}
							isInvalid={touched.Factory && errors.Factory && values.Factory !== 'ALL'}
						>
							{( this.state.authorizedFactory === 'ALL') && <option label='Select...' key=''>''</option>}
							{values.AllFactories.map((val)=>{
								if (values.Factory === val || this.state.authorizedFactory === 'ALL' ) return(<option key={val} label={val}>{val}</option>)
								return true
							})}

						</Form.Control>
						{/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
						<Form.Control.Feedback type='invalid'>
							{errors.Factory}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="3">
							{isSubmitting ? (
								<Spinner animation="border" role="status">
									<span className="sr-only">Loading...</span>
								</Spinner>
							) : (
								(this.state.enableSubmit) ? 
								<Button type="submit"  >Submit form</Button>
								:
								<Button type="submit"  disabled>Submit form</Button>
							)}
						</Form.Group>
					
						{/* <Form.Group as={Col} md="4">
							{<div><h2><Badge variant="success">Success</Badge></h2></div>}
						</Form.Group> */}

					</Form.Row>
					
					
					{ this.state.showCalcFields && <>
					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikGender">
						<Form.Label>Gender</Form.Label>
							<Form.Control
							as="select"
							name="Gender"
							value={values.Gender}
							onChange={handleChange}
							isValid={values.Gender !=='' && !errors.Gender}
							isInvalid={touched.Gender && errors.Gender}
							disabled={values.disableFields}
							>
							<option label='Select...'></option>
							<option label="Women's">Women's</option>
							<option label="Men's">Men's</option>
							</Form.Control>
							<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
							<Form.Control.Feedback type='invalid'>
							{errors.Gender}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikFactoryQuote">
						<Form.Label>Final FOB</Form.Label>
						<Form.Control
							type="text"
							name="FactoryQuote"
							value={values.FactoryQuote}
							onChange={handleChange}
							isValid={values.FactoryQuote && !errors.FactoryQuote}
							isInvalid={errors.FactoryQuote}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.FactoryQuote}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikLeatherUpper">
						<Form.Label>Leather- Upper Grade</Form.Label>
						<Form.Control
							type="text"
							name="LeatherUpper"
							value={values.LeatherUpper}
							onChange={handleChange}
							isValid={values.LeatherUpper && !errors.LeatherUpper}
							isInvalid={errors.LeatherUpper}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.LeatherUpper}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikLeatherLining">
						<Form.Label>Leather- Lining Grade</Form.Label>
						<Form.Control
							type="text"
							name="LeatherLining"
							value={values.LeatherLining}
							onChange={handleChange}
							isValid={values.LeatherLining && !errors.LeatherLining}
							isInvalid={errors.LeatherLining}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.LeatherLining}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikTextileTotal">
						<Form.Label>Non Leather</Form.Label>
						<Form.Control
							type="text"
							name="TextileTotal"
							value={values.TextileTotal}
							onChange={handleChange}
							isValid={values.TextileTotal && !errors.TextileTotal}
							isInvalid={errors.TextileTotal}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.TextileTotal}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikComponents">
						<Form.Label>Shoe Components</Form.Label>
						<Form.Control
							type="text"
							name="Components"
							value={values.Components}
							onChange={handleChange}
							isValid={values.Components && !errors.Components}
							isInvalid={errors.Components}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.Components}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikFootbed">
						<Form.Label>Footbed</Form.Label>
						<Form.Control
							type="text"
							name="Footbed"
							value={values.Footbed}
							onChange={handleChange}
							isValid={values.Footbed && !errors.Footbed}
							isInvalid={errors.Footbed}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.Footbed}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikOutsole">
						<Form.Label>Outsole Components</Form.Label>
						<Form.Control
							type="text"
							name="Outsole"
							value={values.Outsole}
							onChange={handleChange}
							isValid={values.Outsole && !errors.Outsole}
							isInvalid={errors.Outsole}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.Outsole}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikTrims">
						<Form.Label>Trims</Form.Label>
						<Form.Control
							type="text"
							name="Trims"
							value={values.Trims}
							onChange={handleChange}
							isValid={values.Trims && !errors.Trims}
							isInvalid={errors.Trims}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.Trims}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikFinish">
						<Form.Label>Finish</Form.Label>
						<Form.Control
							type="text"
							name="Finish"
							value={values.Finish}
							onChange={handleChange}
							isValid={values.Finish && !errors.Finish}
							isInvalid={errors.Finish}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.Finish}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikPackaging">
						<Form.Label>Packaging</Form.Label>
						<Form.Control
							type="text"
							name="Packaging"
							value={values.Packaging}
							onChange={handleChange}
							isValid={values.Packaging && !errors.Packaging}
							isInvalid={errors.Packaging}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.Packaging}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikExtraLabor">
						<Form.Label>Extra Labor</Form.Label>
						<Form.Control
							type="text"
							name="ExtraLabor"
							value={values.ExtraLabor}
							onChange={handleChange}
							isValid={values.ExtraLabor && !errors.ExtraLabor}
							isInvalid={errors.ExtraLabor}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.ExtraLabor}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					
					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikDiscount">
						<Form.Label>Discount</Form.Label>
						<Form.Control
							type="text"
							name="Discount"
							value={values.Discount}
							onChange={handleChange}
							isValid={values.Discount && !errors.Discount}
							isInvalid={errors.Discount}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.Discount}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikLOP">
						<Form.Label>LOP</Form.Label>
						<Form.Control
							
							type="text"
							name="LOP"
							value={values.LOP}
							onChange={handleChange}
							isValid={values.LOP && !errors.LOP}
							isInvalid={errors.LOP}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.LOP}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikAmortization">
						<Form.Label>Amortization</Form.Label>
						<Form.Control
							type="text"
							name="Amortization"
							value={values.Amortization}
							onChange={handleChange}
							isValid={values.Amortization && !errors.Amortization}
							isInvalid={errors.Amortization}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.Amortization}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikCuttingDies">
						<Form.Label>Cutting Dies</Form.Label>
						<Form.Control
							type="text"
							name="CuttingDies"
							value={values.CuttingDies}
							onChange={handleChange}
							isValid={values.CuttingDies && !errors.CuttingDies}
							isInvalid={errors.CuttingDies}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.CuttingDies}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						<Form.Group as={Col} md="8" controlId="validationFormikLasts">
						<Form.Label>Lasts</Form.Label>
						<Form.Control
							type="text"
							name="Lasts"
							value={values.Lasts}
							onChange={handleChange}
							isValid={values.Lasts && !errors.Lasts}
							isInvalid={errors.Lasts}
							disabled={values.disableFields}
						/>
						<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
						<Form.Control.Feedback type='invalid'>
							{errors.Lasts}
						</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
					

					{/* <Form.Group>
						<Form.Check
						required
						name="terms"
						label="Agree to terms and conditions"
						onChange={handleChange}
						isInvalid={touched.terms && !!errors.terms}
						feedback={errors.terms}
						id="validationFormik0"
						/>
					</Form.Group> */}

					{/* {isSubmitting ? (
						<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
					) : (
						<Button type="submit">Submit form</Button>
					)} */}

					</>}

					</Form>
				)}
				</Formik>

				
			</Container>
			
			);
		}
	}

}) // END OF REACT FORM OBJECT

const MyOnChangeComponent = React.forwardRef((props, ref) => {
	
	const { values, setFieldValue } = formik.useFormikContext();
	
	useEffect(() => {

		const totalFields = getShoeComponentNumericFields()

		var total = 0;

		// this looks at the getShoeComponentNumericFields
		// if the val for each key of that set is true it adds to the total
		// -1 subtracts and 0 does not add.
		// that's all it does - it is a way to understand the math for the total
		// without having to create a different map as getShoeComponentNumericFields 
		// is used elsewhere.
		for (let [key, value] of Object.entries(values)) {
			if (totalFields[key] && !isNaN(value*totalFields[key])) {
				total = Math.round((total + (value*totalFields[key]) ) * 100)/100;
			}
		}

		if (total !== values.FactoryQuote*1) {
			setFieldValue('FactoryQuote',total)
		}
		
	}, [values, setFieldValue, ref]);

	return null;
})

// Since this is one of the gates for using the application
//  we are also using this to set data like seasons and factories. 
// 	factory list is tied to user auth but seasons are not. Still makes sense to do it here
// checkUser could be renamed - getConfig or getSetup or somethingl like that however
// checkUser conveys importance of function
async function checkUser() {
	if (this.props.authState.isAuthenticated && !this.state.userInfo) {

		var userInfo, authorizedFactory, factoryList, seasonList;

		try {
			userInfo = await this.props.authService.getUser();
			// console.log('getting user',userInfo.email)
			const userEmail = userInfo.email

			if (!this.state.seasonList){
				
				const seasonData = await this.getConstantsFromAirtable()
				// console.log(seasonData)
				seasonList = seasonData.seasonList
			}

			if (!this.state.authorizedFactory){
				// console.log('getting factory')
				const factoryData = await this.getAuthorizedFactoryFromAirtable(userEmail)
				authorizedFactory = factoryData.authorizedFactory
				factoryList = factoryData.factoryList
				
				if (!authorizedFactory) {
					this.setState({loadingMessage: 'Your login has not been assigned a factory. Please check with your administrator.'})
					clearTimeout(this.loadingTimeout)
				} 
				
			}
			
			this.setState({ userInfo, authorizedFactory, factoryList, seasonList });

		} catch (error) {
			console.error(error)
			// TODO log out?
		}
	}
}

async function getAuthorizedFactoryFromAirtable (userEmail) {

	// console.log(userEmail)

	const data = {email:userEmail}

	try {

		const accessToken = await this.getUserToken();

		let res = await axios.post(`${apiHost}/costing/get/factoryauthorization`, 
			data,
			{ headers: { 
				'Authorization': `'Bearer ${accessToken}'`
			}}
		)

		return res.data // TODO what is bad factory

	} catch (error) {
		console.error (error)
		this.stopSpinner()
		return false
	}
}

async function getConstantsFromAirtable () {

	// console.log(userEmail)

	const data = {}

	try {

		const accessToken = await this.getUserToken();

		let res = await axios.post(`${apiHost}/costing/get/constants`, 
			data,
			{ headers: { 
				'Authorization': `'Bearer ${accessToken}'`
			}}
		)

		return res.data // TODO what is bad factory

	} catch (error) {
		console.error (error)
		this.stopSpinner()
		return false
	}
}

async function getToken(){
	
	if (this.props.authState.isAuthenticated)
		return this.props.authState.accessToken
	else return false

}

function getSelectFieldMatchFromCBD(data, list){
	var match = false
	if (data){
		list.forEach(element => {
			if (element.name &&  data.toLowerCase() === element.name.toLowerCase() ) match = element.name
		});
	}
	return match
}

function getGenderMatchFromCBD(data){
	// console.log('womenmen = ', data)

	try {
		data = data.split("'")[0]
		data = data.split('s')[0]
		data = data.toLowerCase()
		// console.log('womenmen = ', data)
	
		if (data === 'women') 	return "Women's"
		if (data === 'men')		return "Men's"
	} catch (error) {
		return false
	}

}

function scrubStockNumber(data){
	
	if (data){
		// replace spaces
		let dataNoSpaces = data.replace(/\s/g, '')
		// split dots
		let potentialStockNumber = dataNoSpaces.split('.')[0]
		if ( potentialStockNumber.match(/[a-zA-Z]{1}\d{5}/) ){
			return dataNoSpaces
		} else {
			return false
		}
	} else {
		return false
	}
}

function getSampleStagesList(){
	return [{name:'First Proto'}, {name:'Second Proto'}, {name:'Road Lot'}, {name:'Production'}]
}



const getShoeComponentNumericFields = () => {
	
	return {
		'LeatherUpper':			true,
		'LeatherLining':		true,
		'TextileTotal':			true,
		'Components':			true,
		'Outsole':			 	true,
		'Footbed':			 	true,
		'Trims':			 	true,
		'Finish':			 	true,
		'Packaging':			true,
		'ExtraLabor':			true,
		'Amortization':			true,
		'LOP':			 		true,
		'Discount':			 	-1,
		'CuttingDies': 			0,
		'Lasts':				0
	}
}

// Key on the left is Air Table Key
// Key on the right is React App key 
// or "as-is" if true
// not mapped if false
const getAirMapKeys = () => {
	return {
	"Proto Number"			:"ProtoNumber",
	"Season"				:true,
	"Factory"				:true,
	"Date"					:false,
	"Sample Stage"			:"SampleStage",
	"Product Developer"		:false,
	"Gender"				:true,
	"Pattern Name"			:false,
	"Stock Number"			:"StockNumber",
	"Status"				:false,
	"Carryover Status"		:false,
	"Factory Quote"			:"FactoryQuote",
	"FOB UP/DOWN"			:false,
	"Leather- Upper Grade"	:"LeatherUpper",
	"Leather- Lining Grade"	:"LeatherLining",
	"Non Leathers"			:"TextileTotal",
	"Shoe Components"		:"Components",
	"Outsole Components"	:"Outsole",
	"Footbed"				:true,
	"Trims"					:true,
	"Finish"				:true,
	"Packaging"				:true,
	"Extra Labor"			:"ExtraLabor",
	"Amortization"			:true,
	"LOP"					:true,
	"Discount"				:true,
	"Cutting Dies"			:"CuttingDies",
	"Lasts"					:true,
	"Costing"				:false,
	"Thomas"				:false,
	"Annie"					:false,
	"CH Tech"				:false,
	"US PD"					:false,
	"CBD"					:false,
	}
};
// TODO - if there is no key match - throw an error
const getCBDMapKeys = () => {
	return { 
		'Proto'					: 'ProtoNumber',
		'Sample Stage'			: 'SampleStage',
		'Final FOB'				: 'FactoryQuote',
		'Leather-Upper Grade'	: 'LeatherUpper',
		'Leather-Lining Grade'	: 'LeatherLining',
		'Textile/Non Leather'	: 'TextileTotal',
		// 'Textile/\r\nNon Leather' : 'TextileTotal',
		'Shoe components'		: 'Components',
		'Footbed'				: true,
		'Outsole components'	: 'Outsole',
		'Trims'					: true,
		'Finish'				: true,
		'Packaging'				: true,
		'Extra Labor'			: 'ExtraLabor',
		'Discount'				: true,
		'LOP'					: true,
		'Amortization'			: true,
		'Cutting Dies'			: 'CuttingDies',
		'Lasts'					: true 
	}
}


